.image {
  img {
    &.is-cover {
      object-fit: cover;
    }

    &.is-contain {
      object-fit: contain;
    }
  }
}

.tags-filters {
  .tag:not(.is-underlined) {
    border: 1px solid $primary;
    color: $primary;
  }
}

.tabs {
  &.is-offset,
  .b-tabs.is-offset & {
    margin-left: -$button-padding-horizontal;
  }

  li > a.has-notification {
    span {
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: -10px;
        top: 0;
        border-radius: 6px;
        width: 6px;
        height: 6px;
        background: $red;
      }
    }
  }
}

.navbar {
  @include touch {
    .navbar-menu {
      max-height: calc(100vh - $navbar-height);
      overflow: auto;

      .navbar-end {
        > .navbar-item {
          margin-bottom: 16px;
          background: $primary-dark;
          font-weight: $weight-semibold;
          font-size: $size-6;

          .navbar-dropdown {
            font-weight: $weight-normal;

            .navbar-item {
              padding-left: 24px;
            }
          }
        }

        form .field {
          .input {
            background: $primary-dark;
            color: $white;
            border: 0;

            &::placeholder {
              color: #ffffff99;
            }
          }
        }
      }
    }

    .navbar-burger {
      margin-right: -16px;

      &,
      &:hover {
        background: transparent;
        color: $white;
      }
    }

    .navbar-brand {
      margin-left: -16px;
    }
  }

  .navbar-end {
    align-items: center;
  }

  .navbar-item {
    .navbar-dropdown {
      padding: 0.5rem;
    }
  }

  .navbar-item:not(.has-dropdown),
  .navbar-link {
    padding: 1rem;
  }

  .navbar-dropdown {
    min-width: 180px;
    font-size: $size-7;

    a.navbar-item {
      padding: 0.5rem;
    }
  }
}

.carousel {
  &.is-dotted {
    margin-left: -$gap;
    margin-right: -$gap;

    // .flickity-viewport {
    //   transition: height 0.2s;
    // }

    .flickity-page-dots {
      .flickity-page-dot {
        background: $grey-light;

        &.is-selected {
          background: $primary;
        }
      }
    }

    .carousel-item .box {
      min-height: 240px;
    }
  }

  &.is-hidden-tablet {
    .carousel-item {
      width: 80%;
      margin-right: 16px;
    }
  }

  &.is-quotes {
    .carousel-item {
      width: 100%;
    }
  }

  .carousel-container:has(.flickity-page-dots) {
    padding-bottom: 25px;
  }
}
