.menu {
  &.is-sticky {
    position: sticky;
    top: 40px;
    max-width: 220px;
  }

  .menu-list {
    font-size: $size-8;

    li a {
      border-left: 4px solid $grey-light;
      border-radius: 0;

      &.is-active {
        border-color: $primary;
      }

      &:hover:not(.is-active) {
        border-color: $black;
      }
    }
  }
}
