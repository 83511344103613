.columns {
  &.is-scrollable {
    @include touch {
      overflow: auto !important;
      flex-wrap: nowrap !important;
    }
  }

  &.is-variable {
    margin-top: calc(-1 * var(--columnGap));
    margin-bottom: calc(-1 * var(--columnGap));

    &:not(:last-child) {
      margin-bottom: calc($block-spacing - var(--columnGap));
    }

    > .column {
      padding: var(--columnGap);
    }
  }

  &.is-grid {
    .box {
      height: 100%;
    }
  }

  .column {
    @include mobile {
      &.is-second-mobile {
        order: 2;
      }
    }
  }
}
