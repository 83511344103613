@import 'variables.scss';
@import 'buefy/src/scss/utils/_all.scss';

$loading-background: rgba(255, 255, 255, 0.5);
$loading-background-legacy: $black;
// $dropdown-background-color: $black;

// $checkbox-background-color: $grey;
// $checkbox-border-color: $grey-light;
// $checkbox-border-radius: $size-3;
// $checkbox-border-width: 1px;
// $checkbox-size: $size-3;

@import 'buefy/src/scss/components/_dropdown.scss';
@import 'buefy/src/scss/components/_form.scss';
@import 'buefy/src/scss/components/_radio.scss';
@import 'buefy/src/scss/components/_table.scss';
@import 'buefy/src/scss/components/_tag.scss';
@import 'buefy/src/scss/components/_loading.scss';
@import 'buefy/src/scss/components/_modal.scss';
@import 'buefy/src/scss/components/_pagination.scss';

.b-table .table {
  border-radius: 0;

  thead th {
  }

  td {
    vertical-align: middle;
  }

  tr.is-empty td {
    text-align: center;
  }
}

.b-tabs {
  .is-toggle {
    margin-bottom: 48px;
  }
}

.dropdown {
  .dropdown-trigger .button {
    min-width: $dropdown-menu-min-width;

    .tag {
      padding: 4px 8px;
    }
  }

  &.is-searchable {
    .dropdown-content {
      padding-top: 0;
      padding-bottom: 0;

      .dropdown-item:not(:first-child):last-child {
        margin-bottom: 6px;
      }
    }
  }

  &.is-expanded .dropdown-menu {
    width: auto;
  }

  .dropdown-menu {
    min-width: 100%;

    .dropdown-content {
      border: 1px solid $grey-light;
      max-height: 320px;
      overflow: auto;
      min-width: 100%;

      .dropdown-search-item {
        position: sticky;
        top: 0;
        padding: 12px;
        background: $white;
        z-index: 1;
      }
    }
  }

  &.is-narrow {
    .dropdown-trigger .button {
      min-width: 100px;
    }

    .dropdown-menu .dropdown-content {
      min-width: auto;
    }
  }
}

.pagination {
  .pagination-link .icon {
    font-size: $size-5;
  }
}
