.box {
  .box-info {
    flex: 1;
    padding: 12px 16px 16px;

    &.is-large {
      padding: 16px 24px;

      small:first-child {
        font-size: $size-7;
      }
    }

    small {
      color: $grey;
    }

    .title {
      margin-top: 4px;
      flex: 1;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &.is-article {
    &,
    & .box-info {
      display: flex;
      flex-direction: column;
    }
  }

  &.is-horizontal {
    @include tablet {
      flex-direction: row !important;

      .image-container {
        width: 37.5%;
      }
    }
  }

  &.is-profesor {
    .box-info {
      padding: 16px;
    }

    img {
      margin-left: auto;
      display: block;
    }
  }

  &.is-link {
    border-left: 2px solid $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;

    small {
      font-size: $size-7;
    }

    .icon {
      color: $primary;
    }

    &.is-primary {
      border-left-color: $primary-light;

      img {
        height: 40px;
        margin-bottom: 16px;
      }
    }
  }

  &.is-quote {
    background: transparent;
    color: $white;
    display: flex;
    justify-content: center;
    padding: 0 2rem;

    .columns {
      max-width: 850px;
      text-align: center;

      @include tablet {
        text-align: left;

        .image img {
          border-radius: 0;
        }

        h4 {
          margin-top: 16px;
        }
      }
    }

    .image img {
      border-radius: 100%;
      margin: 0 auto;
      width: 180px;
      height: 180px;
    }
  }

  &.is-article-page {
    padding: 1.5rem 1rem;
    margin: 0 -1rem;

    @include tablet {
      padding: 2rem 3rem;
      margin: 0;
    }
  }
}

a.box {
  &.is-primary {
    background: $primary;
    color: $white;

    small,
    .icon {
      color: $white;
    }
  }

  @include desktop {
    &:hover {
      background: $primary;
      color: $white;

      small,
      .icon {
        color: $white;
      }
    }
  }
}
