@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');

h1,
h2,
h3,
h4,
h5 {
  font-weight: $weight-semibold;

  // @include touch {
  //   overflow-wrap: break-word;
  // }
}

// .icon svg {
//   width: 1em;
//   height: 1em;
//   fill: currentColor;
// }

$weight-black: 900;

small {
  line-height: 1.45;
}

.title {
  hyphens: auto;
}

// set line-heights corresponding to sizes (+ responsive)
@for $i from 1 through length($line-heights) {
  $lh: nth($line-heights, $i);

  .is-size-#{$i} {
    line-height: #{$lh};
  }

  @include mobile {
    .is-size-#{$i}-mobile {
      line-height: #{$lh};
    }
  }

  @include tablet {
    .is-size-#{$i}-tablet {
      line-height: #{$lh};
    }
  }

  @include touch {
    .is-size-#{$i}-touch {
      line-height: #{$lh};
    }
  }

  @include desktop {
    .is-size-#{$i}-desktop {
      line-height: #{$lh};
    }
  }

  @include widescreen {
    .is-size-#{$i}-widescreen {
      line-height: #{$lh};
    }
  }

  @include fullhd {
    .is-size-#{$i}-fullhd {
      line-height: #{$lh};
    }
  }
}
