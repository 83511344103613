#timetable-component {
  padding: 24px;

  .b-radio.button {
    padding-left: 16px;
    padding-right: 16px;
    text-align: left;
    justify-content: flex-start;

    &:not(.is-selected) {
      background: transparent;
      color: $primary;
      border-color: $primary;
    }
  }

  .table {
    td {
      border: none;
      text-transform: capitalize;
    }
  }
}
