//////////////////////////////////////// COLOURS

$white: #ffffff;
$black: #182023;

$grey-darker: #232d34;
$grey-dark: #2a3946;
$grey: #6c757d;
$grey-light: #d2d9e0;
$grey-lighter: #e4e7eb;

$red: #c82845;
$background: #f4f7f6;

$primary-dark: #014696;
$primary: #0859b6;
$primary-light: #1774de;
$primary-lighter: #f0f7ff;

$dark: $primary-dark;
$light: $primary-light;

$primary-invert: $white;
$border: $grey-lighter;

// $custom-colors: (
//   'primary-dark': (
//     $primary,
//     $white,
//   ),
// );

//////////////////////////////////////// BULMA OVERWRITE

// body
$family-primary: 'IBM Plex Sans', sans-serif;

$body-background-color: $background;
$body-color: $black;
$body-size: 16px;

$radius-small: 8px;
$radius: 0;
$radius-medium: 24px;
$radius-large: 40px;
$radius-rounded: 250px;

// navbar
$navbar-height: 5.25rem; // 84px
$navbar-item-img-max-height: 36px;
$navbar-background-color: $primary;

$navbar-item-color: $white;
$navbar-item-hover-color: $white;
$navbar-item-hover-background-color: $primary-dark;
$navbar-item-active-color: $white;
$navbar-item-active-background-color: $primary-dark;

$navbar-dropdown-background-color: $primary-dark;
$navbar-dropdown-item-color: $white;
$navbar-dropdown-item-hover-color: $white;
$navbar-dropdown-item-active-background-color: transparent;
$navbar-dropdown-arrow: $white;
$navbar-dropdown-item-hover-background-color: $primary;
$navbar-dropdown-offset: 0;
$navbar-dropdown-border-top: none;
$navbar-dropdown-boxed-radius: 0;
$navbar-dropdown-boxed-shadow: none;

// layout
$gap: 16px;
$column-gap: 16px;
$block-spacing: 16px;
$section-padding: 40px 0 80px;
$section-padding-desktop: 40px 0 80px;
$section-padding-medium: 64px 0 48px;
$section-padding-large: 80px 0 120px;
$footer-color: $white;
$footer-background-color: $grey-dark;
$footer-padding: 0 0 30px;

// typo + sizes
$size-1: clamp(2.75rem, 3.703vw, 4rem); // 44-64px
$size-2: clamp(2.125rem, 2.7778vw, 3rem); // 34-48px
$size-3: clamp(1.625rem, 1.851vw, 2rem); // 26-32px
$size-4: clamp(1.25rem, 1.3888vw, 1.5rem); // 20-24px
$size-5: clamp(1.25rem, 1.273vw, 1.375rem); // 20-22px
$size-6: 1.125rem; // 18px
$size-7: 1rem; // 16px
$size-8: 0.875rem; // 14px

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8;
$size-small: $size-8;
$size-normal: $size-7;
$size-medium: $size-6;
$size-large: $size-5;

$lh-1: 1.1;
$lh-2: 1.2;
$lh-3: 1.3;
$lh-4: 1.3;
$lh-5: 1.35;
$lh-6: 1.35;
$lh-7: 1.45;
$lh-8: 1.45;
$line-heights: $lh-1 $lh-2 $lh-3 $lh-4 $lh-5 $lh-6 $lh-7 $lh-8;

$spacing-values: (
  '0': 0,
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 16px,
  '4': 20px,
  '5': 24px,
  '6': 32px,
  '7': 48px,
  '8': 64px,
  '9': 80px,
  '10': 120px,
  'auto': auto,
);

$content-heading-color: $black;
$title-weight: 600;
// $weight-medium: 450;

// component colors
$link: $primary;
$link-hover: $primary;
$title-color: inherit;
$subtitle-color: $grey;

// form
$input-focus-box-shadow-size: 0;
$input-shadow: none;
$input-radius: 0;
$label-color: $grey;
$label-weight: normal;

// modals
$modal-content-width: 750px;
$modal-card-body-padding: 32px;
$modal-card-head-padding: 32px;
$modal-close-dimensions: 56px;
$modal-close-top: 6px;
$modal-close-right: 6px;
$modal-background-background-color: rgba(0, 0, 0, 0.7);
$modal-card-body-background-color: $white;
$modal-card-head-background-color: $white;
$modal-card-footer-background-color: $white;
$modal-card-title-color: $black;
$modal-card-head-radius: 0;
$modal-card-foot-radius: 0;

// content
$content-blockquote-background-color: $white;
$content-blockquote-border-left: 0;
$content-blockquote-padding: 20px 0;
$tag-radius: $radius;
$hr-background-color: $grey-light;
$hr-margin: 2.5rem 0;
$hr-height: 1px;

// box
$box-shadow: none;
$box-background-color: $white;
$box-padding: 0;
$box-link-hover-shadow: none;
$box-link-active-shadow: none;
$box-radius: 0;

// aside menu
$menu-item-color: $grey;
$menu-item-hover-color: $black;
$menu-item-hover-background-color: transparent;
$menu-item-active-color: $black;
$menu-item-active-background-color: transparent;
$menu-item-hover-background-color: transparent;

// table
// $table-color: $grey-darker;
// $table-head-cell-color: $grey-darker;
$table-head-background-color: $white;
$table-cell-padding: 12px 8px;
$table-striped-row-even-background-color: #f4f7f680;
$table-head-cell-border-width: 0 0 1px;

// tabs
$tabs-link-color: $white;
$tabs-link-padding: 1.125rem 1.5rem;
$tabs-toggle-link-border-width: 0;
$tabs-toggle-link-radius: 0;
$tabs-toggle-link-background: $primary;
$tabs-toggle-link-active-color: $black;
$tabs-toggle-link-active-background-color: $background;

// controls
$control-height: 50px;
$control-radius: 0;
$control-padding-horizontal: 24px;

$button-padding-horizontal: 24px;
$button-static-background-color: none;
$button-static-border-color: transparent;
$button-static-color: none;
$button-focus-box-shadow-size: 0;

$dropdown-content-padding-top: 6px;
$dropdown-content-padding-bottom: 6px;
$dropdown-content-radius: 0;
$dropdown-menu-min-width: 180px;
// $dropdown-content-background-color: $grey-dark;
// $dropdown-item-color: $white;

// screen
$desktop: 1080px;
// $widescreen: 1366px;
$widescreen: 1440px;
$fullhd: 1620px;

@import 'bulma/sass/base/generic.sass';
