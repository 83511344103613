article {
  > :first-child {
    padding-top: 0;

    hr {
      display: none;
    }
  }

  > :last-child {
    padding-bottom: 0;
  }

  .block-heading_block {
    h2 {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .block-subtitle_block {
    h3 {
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }
  }

  .block-paragraph_block {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    a {
      color: $primary;
      text-decoration: underline;
      word-break: break-all;
    }
  }

  .block-emphasis {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .content {
      font-weight: $weight-medium;
      color: #635938;
      background-color: #fff3cc;
      border: 1px solid #f3e09e;
      padding: 1rem 1.5rem;
    }
  }

  .block-image_block {
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include desktop {
      margin-left: -80px;
      margin-right: -80px;
    }
  }

  .block-logo_grid {
    padding: 1rem 0 2rem;

    .box {
      border: 1px solid $grey-lighter;
      margin-right: -1px;
      margin-bottom: -1px;
    }
  }

  .block-documents {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .button {
      margin-top: 8px;
      margin-bottom: 8px;
      padding: 1rem 1.5rem;
      height: auto;
      white-space: normal;

      .document-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: -8px;
        margin-right: 12px;

        .icon {
          margin: 0;
        }

        small {
          font-size: 11px;
          color: $grey;
          font-weight: $weight-medium;
        }
      }
    }
  }

  .block-pages_links,
  .block-links {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .block-persons {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .box {
      padding: 1rem;
      border: 1px solid $border;
      display: flex;
      align-items: center;

      .box-info {
        padding: 0;
      }

      .image img {
        width: 96px;
        height: 96px;
      }
    }
  }

  .block-q_a_accordion {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .box {
      border: 1px solid $border;
      padding: 0;

      > .button {
        height: 72px;
        justify-content: space-between;
        border: none;

        &.is-active {
          & + .is-body {
            display: block;
          }

          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }

    .is-body {
      padding: 0 1.5rem 1rem;
      background: $white;
      display: none;

      .block-text {
        padding-top: 1rem;
        border-top: 1px solid $border;
      }
    }
  }

  .block-block_quote {
    padding: 1.5rem 0;

    .box {
      color: $primary;
      border: 1px solid $primary-light;
      background: $primary-lighter;
      padding: 1.5rem;

      .image img {
        width: 120px;
        height: 120px;
        margin: 0 auto;
      }

      @include tablet {
        padding-right: 4rem;
      }
    }
  }
}

.block-timeline {
  .content {
    padding: 1rem 0;

    ul {
      margin-left: $size-5;

      li {
        &::marker {
          font-size: 1.3125rem;
          color: $grey-light;
        }

        &:not(:last-child) {
          margin-bottom: 1rem;
          position: relative;

          &:before {
            content: '';
            display: block;
            height: calc(100% + 1rem);
            position: absolute;
            top: 30px;
            left: -18px;
            border-left: 1px solid $grey-light;
          }
        }
      }
    }

    .box {
      padding: 1rem;
    }
  }
}

.block-table {
  padding: 1rem 0 1.5rem;

  .table-container {
    overflow: auto;
    padding: 0.5rem 1rem 1rem;
    background: $white;
    border: 1px solid $border;
    max-height: 80vh;

    .table {
      min-width: 100%;

      caption {
        font-size: $size-8;
        font-weight: $weight-semibold;
        margin: 8px 0 12px;
      }

      thead {
        position: sticky;
        top: -0.5rem;
        z-index: 1;

        th {
          font-weight: $weight-semibold;

          // &:first-child {
          //   position: sticky;
          //   left: 0;
          //   z-index: 1;
          // }
        }
      }

      tbody {
        font-size: $size-8;

        // th {
        //   position: sticky;
        //   left: 0;
        //   z-index: 1;
        // }
      }
    }
  }
}

.block-carousel {
  padding: 2.5rem 0;

  @include desktop {
    margin-left: -80px;
    margin-right: -80px;
  }

  .carousel {
    overflow: hidden;
    background: $primary;

    .carousel-container {
      opacity: 0;
      transition: opacity 0.4s;

      &.flickity-enabled {
        opacity: 1;
      }

      .carousel-item:not(.is-selected) {
        opacity: 0.5;
        filter: grayscale(1);
      }
    }

    .image img {
      height: 250px;
      width: auto;
      max-width: none;

      @include tablet {
        height: 340px;
      }

      @include desktop {
        height: 640px;
      }
    }

    footer {
      color: $white;
      padding: 1rem 1.5rem;

      @include desktop {
        display: flex;

        .wrapper {
          flex: 1;
          padding-right: 24px;
        }
      }

      nav {
        text-align: right;

        @include mobile {
          margin-top: 16px;
        }

        .button {
          border-color: $primary-light;
        }
      }
    }
  }
}

.block-embed_block {
  padding-bottom: 2.5rem;

  @include desktop {
    margin-left: -80px;
    margin-right: -80px;
  }

  .image {
    margin-top: 2.5rem;
    background: $dark;
  }

  .image > * {
    @extend .is-overlay;
    position: absolute !important;
    height: 100% !important;
    width: 100% !important;
  }
}
