html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;

  main {
    flex: 1;

    section.has-tabs {
      margin-top: -100px;
    }

    #js-tothetop {
      position: fixed;
      right: 25px;
      bottom: -60px;
      width: 60px;
      height: 60px;
      z-index: 9;
      transition: bottom 0.2s ease-out;

      &.is-visible {
        bottom: 25px;
      }
    }
  }
}

.container {
  padding-left: $gap;
  padding-right: $gap;

  @include desktop {
    padding-left: 3 * $gap;
    padding-right: 3 * $gap;
  }

  .is-limited {
    max-width: 700px;

    &.is-medium {
      max-width: 800px;
    }

    &.is-large {
      max-width: 980px;
    }

    &.is-page {
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;
    }
  }

  &.is-widescreen {
    max-width: $widescreen !important;
  }
}

header.header-main {
  background: linear-gradient(90deg, $primary-dark 20%, $primary 80%);
  color: $white;

  .section {
    position: relative;

    .is-limited {
      isolation: isolate;
    }

    &.has-tabs {
      padding: 8px 0 64px;
    }

    a {
      color: $white;
    }

    .image.is-overlay {
      position: absolute;
      overflow: hidden;

      img {
        filter: grayscale(1);
        mix-blend-mode: multiply;
        opacity: 35%;
        height: 100%;
        object-position: top;
      }

      &:after {
        @extend %overlay;
        content: '';
        display: block;

        background: linear-gradient(
          90deg,
          $primary-dark 0%,
          transparent 50%,
          $primary 100%
        );
      }
    }
  }

  .breadcrumb {
    color: $white;
    padding: 16px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    a {
      color: $white;
      overflow: hidden;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .links {
    .icon-text {
      &:not(:last-child) {
        margin-bottom: 16px;
        display: block;

        @include tablet {
          margin-bottom: 0;
          margin-right: 32px;
          display: inline-flex;
        }
      }
    }
  }
}

footer {
  &.footer {
    header {
      padding: 24px 0;

      a {
        iconify-icon {
          display: block;
        }

        & + a {
          margin-left: 1rem;
        }
      }
    }

    a {
      color: $white;
    }
  }

  &.is-nav {
    .button {
      height: auto;

      h4 {
        margin-bottom: 1.5rem;
      }
    }
  }
}

aside {
  &.is-notice-news {
    @include tablet {
      margin-top: 176px;
    }
  }
}
